import React from "react"
import { StoryGroup } from "components"

const eBooks = [
  {
    title: "DOM API Kullanımı",
    url: "https://learnreactui.dev/contents/dom-api-kullanimi",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Fce3a2726-5b41-4ba6-9472-bbd6cc494f49%2Fcovers%2FDOM_Cover2.png",
  },
]

export const LinkMap = {
  DOM: "cf1e69e615b",
  "Template Rendering": "6c8a97e4ef59",
  VDOM: "ea122d63eb7d",
  JSX: "ea122d63eb7d",
  Reconciliation: "ea122d63eb7d",
  "Diffing Algorithm": "ea122d63eb7d",
  View: "936648151f1b",
  Decorators: "936648151f1b",
  "Class Fields": "936648151f1b",
  Mixins: "936648151f1b",
  HOC: "936648151f1b",
  "Render Props": "936648151f1b",
  Hooks: "936648151f1b",
  "Shadow DOM": "f1236db55e9b",
  "Custom Elements": "f1236db55e9b",
  "HTML Template": "f1236db55e9b",
  "Web Components": "f1236db55e9b",

  "Handlebar.JS": "c972d2db36c8",
  "Semantic Templates": "c972d2db36c8",
  "Mustache-Compatible": "c972d2db36c8",
  "Fast Execution": "c972d2db36c8",

  "Backbone JS": "d76952229146",
  "Model,Collection-View": "d76952229146",
  "Model-View": "d76952229146",
  "Rest API": "d76952229146",
  "View Rendering": "d76952229146",
  Routing: "d76952229146",

  "Backbone.Event": "c3f28df1a802",
  "Backbone.Model": "c3f28df1a802",
  "Backbone.Collection": "c3f28df1a802",
  "Backbone.Router": "c3f28df1a802",
  "Backbone.History": "c3f28df1a802",
  "Backbone.sync": "c3f28df1a802",
  "Backbone.View": "c3f28df1a802",

  Svelte: "751db72cc24a",
  "Svelte-ReactiveProgramming": "751db72cc24a",
  "Svelte-NotVirtualDOM": "751db72cc24a",
  "Svelte-Performans": "751db72cc24a",

  EmberJS: "3c2a8e11a2a6",
  "HBS file": "3c2a8e11a2a6",
  "Ember.Application": "3c2a8e11a2a6",
  "DS.Store": "3c2a8e11a2a6",
  "DS.LSAdapter": "3c2a8e11a2a6",
}

const stories = [
  {
    title: "DOM API kullanım Yöntemleri",
    postId: "cf1e69e615b",
  },
  {
    title: "JQuery ile DOM Kullanımı",
    postId: "97a680206a5",
  },
  {
    title: "Template Rendering -1 (Mustache.JS)",
    postId: "6c8a97e4ef59",
  },
  {
    title: "Template Rendering -2 (Handlebar.JS)",
    postId: "c972d2db36c8",
  },

  {
    title: "BackboneJS ile DOM Kullanımı -1",
    postId: "d76952229146",
  },
  {
    title: "BackboneJS ile DOM Kullanımı -2",
    postId: "c3f28df1a802",
  },
  {
    title: "EmberJS ile DOM Kullanımı",
    postId: "3c2a8e11a2a6",
  },
  {
    title: "AngularJS ile DOM Kullanımı",
    postId: "f92b0ca1f07b",
  },
  {
    title: "React  ile DOM Kullanımı",
    postId: "ea122d63eb7d",
  },
  {
    title: "Vue  ile DOM Kullanımı",
    postId: "f2f4108b5d6a",
  },
  {
    title: "Shadow DOM, Custom Element ve HTML Template",
    postId: "f1236db55e9b",
  },
  {
    title: "Polymer LitElement ile DOM Kullanımı",
    postId: "a243340281ac",
  },
  {
    title: "Svelte DOM API Kullanımı",
    postId: "751db72cc24a",
  },
  {
    title: "UI Bileşenleri Nasıl Evrimleşti?",
    postId: "936648151f1b",
  },
  {
    title: "iFrame Nedir ? Nerelerde Kullanılır ?",
    postId: "5cf58d66ab77",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "DOM API Kullanımı",
  path: "dom-api-kullanimi",
}

const DOMAPIUsagePage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default DOMAPIUsagePage
